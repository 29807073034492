/**
 * Represents a custom extension of the global `Window` object
 * to include the `CBAR_APP_CONFIG` property.
 */
export interface CbarWindow extends Window {
  /**
   * Configuration for the CBAR application.
   */
  CBAR_APP_CONFIG: CbarAppConfig;
}

/**
 * Defines the structure of the CBAR application configuration.
 */
type CbarAppConfig = {
  /**
   * The theme applied to the application.
   *
   * - `'md'`: Material design theme.
   * - `string`: A custom theme identifier.
   */
  theme?: 'md' | string;
};

/**
 * A type-casted reference to the global `window` object
 * that includes the CBAR-specific configuration.
 */
const Cbar = (window as any as CbarWindow);

/**
 * A class to manage the configuration for the CBAR application.
 */
export class Config {
  /**
   * Holds the current configuration.
   */
  public config: CbarAppConfig;

  /**
   * Initializes the `Config` instance with the given configuration.
   *
   * @param config - The initial configuration for the application.
   *                 Defaults to an empty configuration object.
   */
  constructor(config: CbarAppConfig = {}) {
    this.config = config;
  }

  /**
   * Sets the theme for the application and dispatches a
   * `cbar-app-theme-changed` event on the `window` object.
   *
   * @param theme - The theme to apply, either `'md'` or a custom string identifier.
   */
  setTheme(theme: 'md' | string) {
    this.config.theme = theme;

    window.dispatchEvent(new CustomEvent('cbar-app-theme-changed', {
      detail: theme,
    }));
  }
}

/**
 * Singleton instance of the `Config` class.
 */
let config: Config | null = null;

/**
 * Retrieves the singleton configuration instance for the CBAR application.
 * If it doesn't exist yet and `Cbar.CBAR_APP_CONFIG` is defined, it will
 * initialize the configuration instance using the global configuration.
 *
 * @returns The singleton `Config` instance.
 */
export function getConfig(): Config | null {
  if (!config && typeof Cbar !== "undefined") {
    config = new Config(Cbar.CBAR_APP_CONFIG);
  }

  return config;
}