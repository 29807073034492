import {LitElement, html, PropertyValues} from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { CbarThemeableMixin } from "@cbar/cbar-themeable-mixin";
import {DomModule} from '@polymer/polymer/lib/elements/dom-module.js';
import {injectFontFace, AvailableFont} from '@cbar/cbar-fonts';
import '@material/mwc-icon';
import '@cbar/cbar-shared-styles';

export type ThemeContent = Element & {
  content?: any;
};

const themeTemplate : ThemeContent | null = DomModule.import('cbar-shared-styles', 'template');
// @ts-ignore
const sharedThemeElement = themeTemplate?.content?.firstElementChild;

if (!sharedThemeElement) {
  console.warn('The theme template or its first element child is missing.');
}

// @ts-ignore
const sharedTheme = sharedThemeElement?.textContent ?? '';

let oldSize = "medium";

@customElement('cbar-entity-title')

export class CbarEntityTitle extends CbarThemeableMixin(LitElement) {

  @property({type: String}) image = "";

  @property({type: String }) title = "";

  @property({type: String}) size = "medium";

  @property({type: String}) url = "";

  render() {
    return html`
      <style>
        ${sharedTheme}
        :host{
          font-family: Roboto, Noto, sans-serif;
        }
        .wrapper.small img, .wrapper.small mwc-icon{
          min-height: var(--cbar-image-size-small, 20px);
          min-width: var(--cbar-image-size-small, 20px);
          height: var(--cbar-image-size-small, 20px);
          width: var(--cbar-image-size-small, 20px);
          --mdc-icon-size: var(--cbar-image-size-small, 20px);

          margin-right: 8px;
        }
        .wrapper.medium img, .wrapper.medium mwc-icon{
          min-height: var(--cbar-image-size-medium, 24px);
          min-width: var(--cbar-image-size-medium, 24px);
          height: var(--cbar-image-size-medium, 24px);
          width: var(--cbar-image-size-medium, 24px);
          --mdc-icon-size: var(--cbar-image-size-medium, 24px);

          margin-right: 12px;
        }
        .wrapper.large img, .wrapper.large mwc-icon{
          min-height: var(--cbar-image-size-large, 42px);
          min-width: var(--cbar-image-size-large, 42px);
          height: var(--cbar-image-size-large, 42px);
          width: var(--cbar-image-size-large, 42px);
          --mdc-icon-size: var(--cbar-image-size-large, 42px);

          margin-right: 14px;
        }
        .wrapper.small{
          font-size: var(--cbar-typescale-body-small-size, 0.75rem);
        }
        .wrapper.medium{
          font-size: var(--cbar-typescale-body-medium-size, 1rem);
        }
        .wrapper.large{
          font-size: var(--cbar-typescale-body-large-size, 1.25rem);
        }
        img{
          object-fit: cover;
          border-radius: 50%;
        }
        .wrapper, .image-div{
          display: flex;
          align-items: center;
        }
        a{
          text-decoration: none;
        }
        a:hover{
          color: var(--cbar-sys-color-primary, #3e7450)
        }
      </style>
      <div class="wrapper medium">
        <div class="image-div">
            ${this.getImageDiv()}
        </div>
        <div>
          ${this.getTitleDiv()}
        </div>
      </div>`
  }

  constructor() {
    super();

    // Include the material icons font
    injectFontFace(AvailableFont.materialIcons);
  }

  get wrapper(){
    return this?.shadowRoot?.querySelector('.wrapper');
  }

  updated(props: PropertyValues){
    if(props.has('size')){
      this.handleSize(this.size);
    }
  }

  getImageDiv(){
    return this.image ? html`<img src="${this.image}" >` : html`<mwc-icon>group</mwc-icon>`;
  }

  getTitleDiv(){
    return this.url ? html`<a href="${this.url}">${this.title}</a>` : html`<div>${this.title}</div>`
  }

  handleSize(size: string){
    this?.wrapper?.classList.replace(oldSize, size);
    oldSize = size;
  }

}

declare global {
  interface HTMLElementTagNameMap {
    'cbar-entity-title': CbarEntityTitle
  }
}
