import {PolymerElement, html} from '@polymer/polymer/polymer-element';

import '@polymer/paper-spinner/paper-spinner';
import '@material/web/dialog/dialog.js';
import '@cbar/cbar-relationship-selector/cbar-relationship-selector';
import '@cbar/cbar-shared-styles/cbar-shared-styles.js';
import moment from "moment";

/**
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class CCUpdateRole extends PolymerElement {
    static get template() {
        return html`
    <style include="cbar-shared-styles">
      :host {
        display: block;
      }

      cbar-pfb-container {
        --accent-color: #000;

        margin-bottom: 0;
      }

      [hidden] {
        display: none;
      }
    </style>

    <md-dialog with-backdrop open="{{opened}}" on-close="__closeHandler">
      <h6 class="headline">[[firstName]] [[lastName]]</h6>
        <div slot="content">
          <template is="dom-if" if="[[updatingFromSchedule]]">
            <div>Please confirm the details below</div>
          </template>  
          <cbar-relationship-selector
            id="relationshipSelector"
            relationships="[[_availableRoles]]"
            show-start-date="[[showShowStartDate(entityType)]]"
            show-end-date="[[shouldShowEndDate(entityType)]]"
            selected-relationship="{{selectedRole}}"
            selected-start-date="{{selectedStartDate}}"
            selected-end-date="{{selectedEndDate}}"     
            relationships-label="Position" 
            disabled$="[[disabled]]"
            static-time  
          ></cbar-relationship-selector>
        </div>

      <div slot="actions">
        <p hidden="[[!_errorMessage]]">[[_errorMessage]]</p>
        <paper-button hidden$=[[loading]] class="btn-cancel" on-tap="cancel">Cancel</paper-button>
        
        <template is="dom-if" if="[[!updatingFromSchedule]]">
            <paper-button hidden$=[[loading]] on-tap="remove">Remove</paper-button>
        </template>
        
        <paper-button hidden$=[[loading]] on-tap="update">Update</paper-button>

        <paper-spinner active="[[loading]]" hidden$="[[!loading]]"></paper-spinner>
      </div>
    </md-dialog>
`;
    }

    static get is() {
        return 'cc-update-role';
    }

    static get properties() {
        return {
            /**
             * Whether the add person dialog is opened or not
             *
             * @type {Boolean}
             */
            opened: {
                type: Boolean,
                notify: true,
                value: false,
            },

            entityType: String,

            entityId: Number,

            profileId: Number,

            roleToUpdate: {
                type: Object,
                observer: '_roleToUpdateChanged',
            },

            selectedRole: {
                type: Object,
                notify: true,
            },

            selectedStartDate: {
                type: Date,
                notify: true,
            },

            selectedEndDate: {
                type: Date,
                notify: true,
            },

            /**
             * Whether the form is currently loading.
             *
             * Adds a spinner and hides cancel & submit buttons if true.
             *
             * @type {Boolean}
             */
            loading: {
                type: Boolean,
                value: false,
            },
            disabled : {
                type : Boolean,
                value: false
            },
            updatingFromSchedule: {
                type: Boolean,
                value: false
            }
        };
    }

    static get observers() {
        return [
            '_loadAvailableRoles(entityType, entityId)',
        ];
    }

    ready(){
        super.ready();
        requestAnimationFrame(() => this.handleOverflowIssue());
    }

    get dialog(){
        if(!this._dialog){
            this._dialog = this.root.querySelector('md-dialog');
        }
        return this._dialog;
    }

    get relationshipSelector(){
        return this.root.querySelector('cbar-relationship-selector');
    }

    _shouldLoadRoles() {
        return !!this.entityType && !!this.entityId;
    }

    async _roleToUpdateChanged(role) {
        // Find the role in the list of available roles
        if( !this._availableRoles || !role) {
            return;
        }

        let roleFound = false;

        this.profileId = role.ProfileID;

        // trim our custom roles
        let customRole = this._availableRoles.find(role => role.id === 0);
        if(customRole) {
            this.splice('_availableRoles', this._availableRoles.indexOf(customRole), 1);
            await this.animationFrame();
        }

        for( let availableRole of this._availableRoles ) {

            if( availableRole.id === role.Quota ) {
                this.set('selectedRole', availableRole);

                this.handleTitleReset();

                roleFound = true;

                // Now set the start date and time.
                this.selectedStartDate = this._ccDateToDate(role.StartTime, true);
                this.selectedEndDate = this._ccDateToDate(role.EndTime);

                // Run the validator to highlight any validation errors
                // or to remove previous validation errors
                this.validate();

                break;
            }
        }

        if(!roleFound && role.role_id){
            let roleToAdd = {
                id: 0,
                title: role.Title,
                startTime: this._ccDateToDate(role.StartTime, true),
                endTime: this._ccDateToDate(role.EndTime),
                relationshipRoleId: role.RelationshipRole
            }

            let copyRoles = [...this._availableRoles];
            copyRoles.push(roleToAdd);

            this.set('_availableRoles', []);

            // allow the roles to restamp
            await this.animationFrame();

            this.set('_availableRoles', copyRoles);

            // allow the roles to restamp
            await this.animationFrame();

            this.set('selectedRole', roleToAdd);

            this.selectedStartDate = this._ccDateToDate(role.StartTime, true);
            this.selectedEndDate = this._ccDateToDate(role.EndTime);

            this.validate();
        }
    }

    _loadAvailableRoles(entityType, entityId) {
        if( !this._shouldLoadRoles() ) {
            return false;
        }

        this.set('selectedRole', {});
        this.loading = true

        let data = {
            action: 'list-roles-operation',
            source_entity: 'Profile',
            source_entity_id: 0,
            target_entity: entityType,
            target_entity_id: entityId,
        };

        $.ajax({
            url: '/ajax/entity-roles',
            type: 'POST',
            dataType: 'json',
            data,
            success: data => {
                this.loading = false;

                if( data.error ) {
                    console.error(data.message);

                    return;
                }

                this._availableRoles = this._formatRolesToCbarRelationships(data.data);
                requestAnimationFrame(() => {
                    if (this.roleToUpdate) {
                        this._roleToUpdateChanged(this.roleToUpdate);
                    }
                    this.loading = false;
                });
            },
            error: xhr => {
                this.loading = false;
                this._errorMessage = xhr.responseJSON ? xhr.responseJSON : xhr.responseText;

                console.error(this._errorMessage);

                this.loading = false;
            }
        });
    }

    _formatRolesToCbarRelationships(roles) {
        let relationships = [];

        if( roles ) {
            for( let role of roles ) {
                let relationship = {
                    id: role.role_id,
                    title: role.role_name,
                    startTime: this._ccDateToDate(role.start_time, true),
                    endTime: this._ccDateToDate(role.end_time),
                };

                relationships.push(relationship);
            }
        }

        return relationships;
    }

    _ccDateToDate(ccDate, returnToday = false) {

        if(!ccDate){
            return returnToday ? new Date() : "";
        }

        const DATE_FORMAT = 'DD-MM-YYYY HH:mm';

        let momentDate = moment(ccDate, DATE_FORMAT);
        if( !momentDate.isValid() ) {
            return returnToday ? new Date() : "";
        }

        return momentDate.toDate();
    }

    _dateToCCDate(date) {
        const DATE_FORMAT = 'DD-MM-YYYY HH:mm';

        let momentDate = moment(date);
        if( !momentDate.isValid() ) {
            return null;
        }

        return momentDate.format(DATE_FORMAT);
    }

    _getParams(action) {
        let validAction = action === 'add' || action === 'remove' || action === 'update';

        if( !validAction || this.entityType === undefined || this.entityId <= 0 || this.quotaId <= 0 || this.profileId <= 0 ) {
            throw new Error("Invalid parameters for Campaign Central entity-roles");
        }

        let params = {
            action: action,
            source_entity: 'Profile',
            name: this.selectedRole.title,
            source_entity_id: this.profileId,
            target_entity: this.entityType,
            target_entity_id: this.entityId,
            quota: this.selectedRole.id !== 0 ? this.selectedRole.id : null,
            relationship_id: this.roleToUpdate.role_id,
            role_type: this.selectedRole.relationshipRoleId ? this.selectedRole.relationshipRoleId : null
        };

        let startDate = this._dateToCCDate(this.selectedStartDate);
        if( startDate ) {
            params.start_time = startDate;
        }

        let endDate = this._dateToCCDate(this.selectedEndDate);
        if( endDate ) {
            params.end_time = endDate;
        }

        return params;
    }

    _makeRequest(action) {
        this.loading = true;

        return new Promise((res, rej) => {
            $.ajax({
                url: '/ajax/entity-roles',
                data: this._getParams(action),
                method: 'post',
                success: data => {
                    res(data);
                },
                error: () => {
                    rej(`An error occurred when attempting to ${action} role`);
                },
                complete: () => {
                    this.loading = false;
                }
            });
        });
    }

    _fireEvent(action, result) {
        // Fires either role-updated or role-removed
        let event = new CustomEvent(`role-${action}d`, {
            bubbles: true,
            composed: true,
            detail: {
                result
            },
            cancelable: true
        });

        this.dispatchEvent(event);
    }

    showShowStartDate(entityType){
        return entityType === "Event" || entityType === "Group"
    }

    shouldShowEndDate(entityType){
        return this.shouldShowDates(entityType)
    }

    shouldShowDates(entityType) {
        return entityType === 'Event' || entityType === "Group";
    }

    open() {
        this.opened = true;
        requestAnimationFrame(() => this.handleOverflowIssue());
    }

    cancel(){
        this.dispatchEvent(new CustomEvent('cancel-clicked', {
            bubbles: true,
            composed: true
        }));

        this.close();
    }

    close() {
        this.opened = false;
    }

    update() {
        if( !this.validate() ) {
            return;
        }

        this._makeRequest('update').then(data => {
            if( !data.error ) {
                this._fireEvent('update', data);
            }
        }).catch(msg => {
            console.error(msg);
        });
    }

    remove() {
        this._makeRequest('remove').then(data => {
            if( !data.error ) {
                this._fireEvent('remove', data);
            }
        }).catch(msg => {
            console.error(msg);
        });
    }

    validate() {
        return this.$.relationshipSelector.validate();
    }

    animationFrame(){
     return new Promise(res => {
         requestAnimationFrame(res);
     })
    }

    syncRole(role){
        let index = this._availableRoles.map(availableRole => availableRole.id).indexOf(role.id);
        let newRoles = [...this._availableRoles];

        newRoles[index] = role;

        this.set('_availableRoles', newRoles);

        this.handleTitleReset();
    }

    handleTitleReset(){
        let selectedValue = this.relationshipSelector.$.relationshipDropdown.value;

        if(!selectedValue){
            return;
        }

        this.relationshipSelector.$.relationshipDropdown.value = null;

        requestAnimationFrame(() => {
            this.relationshipSelector.$.relationshipDropdown.value = selectedValue;
        });
    }

    __closeHandler(e){
        if(this.loading){
            e.preventDefault();
        } else {
            this.opened = false;
        }
    }

    /**
     * Fixes overflow issues for the dialog container and scroller.
     */
    handleOverflowIssue() {
        this.dialog.container.style.overflow = 'visible';
        this.dialog.scroller.style.overflow = 'visible';
    }

}

window.customElements.define(CCUpdateRole.is, CCUpdateRole);
